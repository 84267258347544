import requestService from "../requestService";
import store from '@/store';

export default {
	async createBanner(form) {
		const response = await requestService.post(`/cp_dash/banner`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async updateBanner(uuid, form) {
		const response = await requestService.post(`/cp_dash/banner/${uuid}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getBanners() {
		const response = await requestService.get(`/v1/banner`, {
			headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
		})
		return response?.data
	},
	async getSlidersByGroup(group) {
		const response = await requestService.get(`/v1/slider/${group}`, {
			headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
		})
		return response?.data
	},
	async getBannerForAdmin() {
		const response = await requestService.get(`/cp_dash/banner`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async deleteBanner(id) {
		const response = await requestService.delete(`/cp_dash/banner/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
}